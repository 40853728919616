import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <div className='container'>
      <div className="grid">
        <div className="grid__column">
          <h1 className='title title--xxxl color-primary'>NOT FOUND</h1>
          <p>This page doesn&#39;t exist</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
